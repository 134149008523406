import { useTranslation } from 'react-i18next';

interface IOtpCounter {
  currentMinutes: number | string;
  currentSeconds: number | string;
  handleLogin: () => void;
}

export const OtpCounter = ({ currentMinutes, currentSeconds, handleLogin }: IOtpCounter) => {
  const { t } = useTranslation('common');

  const isCountingDown = +currentMinutes > 0 || +currentSeconds > 0;

  return (
    <div className='flex flex-col justify-center items-center pt-4 text-dark-gray'>
      <div className='text-[20px] '>
        {formatSingleDigit(+currentMinutes)}:{formatSingleDigit(+currentSeconds)}
      </div>
      <div className='flex text-[16px]'>
        <span className={`${isCountingDown ? 'text-dark-gray-disabled' : ''}`}>{t('did_not_received')} </span>{' '}
        <button
          disabled={isCountingDown}
          className={`bg-transparent border-none no-underline ml-1 
        ${isCountingDown ? 'text-dark-gray-disabled' : 'text-apollo-primary cursor-pointer'} `}
          onClick={handleLogin}
        >
          {t('resend')}
        </button>
      </div>
    </div>
  );
};

const formatSingleDigit = (digit: number) => (digit < 10 ? '0' + digit : digit.toString());
