import { PhoneIcon } from 'components/SvgIcons/PhoneIcon';
import { useTranslation } from 'next-i18next';
import React, { useState, useEffect } from 'react';
interface Props {
  error?: string;
  errorEffect?: string;
  value?: string;
  type?: string;
  placeholder?: string;
  Icon?: any | undefined;
  onChange?: any | undefined;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
  className?: string;
  inputType?: 'all' | 'char' | 'num';
}

const PhoneNumberInput = ({
  error,
  value,
  type = 'text',
  Icon,
  placeholder = 'phone_number',
  onChange,
  className = '',
}: Props) => {
  const { t } = useTranslation('common');
  const [phoneNumber, setPhoneNumber] = useState('');

  const formatPhoneNumber = (value) => {
    const digitsOnly = value?.replace(/\D/g, '');
    let formattedValue = '';
    for (let i = 0; i < digitsOnly?.length; i++) {
      if (i === 2 || i === 5) {
        formattedValue += ' ';
      }
      formattedValue += digitsOnly[i];
    }
    return { formattedValue, digitsOnly };
  };

  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value;
    if (!inputValue?.startsWith('0')) {
      const { formattedValue, digitsOnly } = formatPhoneNumber(inputValue);
      if (digitsOnly.length <= 9) {
        setPhoneNumber(formattedValue);
        onChange && onChange(`966${digitsOnly}`);
      }
    }
  };

  useEffect(() => {
    const { formattedValue } = formatPhoneNumber(value?.replace('966', '')?.replace('+', '')?.replaceAll(' ', ''));
    if (value) setPhoneNumber(formattedValue);
  }, [value]);

  return (
    <div
      dir='ltr'
      className={`h-[55px] bg-apollo-dark-white p-2 rounded rounded-b-none ${
        error ? 'border-2 border-b-apollo-error' : 'border-2 border-b-primary'
      }`}
    >
      <div className='relative w-full'>
        <div
          className={`absolute left-[32px] rtl:right-[32px] top-[-2px] text-[12px] px-2 ${
            error ? 'text-apollo-error' : 'text-primary'
          }`}
        >
          {t(placeholder || 'phone_number')}
        </div>
        {Icon ? (
          <span className={`absolute inset-y-0 left-0 flex items-center px-2`}>
            <Icon color={error ? '#E81103' : '#757575'} />
          </span>
        ) : (
          <span className={`absolute inset-y-0 left-0 flex items-center px-2`}>
            <PhoneIcon color={error ? '#E81103' : '#757575'} />
          </span>
        )}
        <div className='absolute inset-y-0 left-8 flex items-center justify-center pt-3 px-2'>
          <span>🇸🇦</span>
          <div className='select-wrapper'>
            <select
              className={`absolute inset-y-0 left-3 mt-[6px] pl-5 pr-7 custom-select-input text-[16px] font-bold outline-none block text-dark-gray placeholder-light-gray h-10 bg-apollo-dark-white rounded`}
            >
              <option selected key={'966'} value={'966'}>
                {'+966'}
              </option>
            </select>
          </div>
          <div className='absolute inset-y-0 left-[110px] mt-[13px] text-light-gray'>|</div>
        </div>
        <input
          className={`${
            Icon ? 'pl-40' : 'px-2'
          } pt-[12px] block w-full placeholder-light-gray outline-none h-10 bg-apollo-dark-white rounded text-[16px] font-bold text-dark-gray ${className}`}
          type={type}
          inputMode='numeric'
          placeholder={t(placeholder) || ''}
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        />
      </div>
    </div>
  );
};

export default PhoneNumberInput;
