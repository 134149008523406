import { SVG } from 'lib/interface';

export const OTPIcon = ({ color = '#757575' }: SVG) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1104_40402)'>
      <path d='M17.6 17.6H16V16H17.6V17.6Z' fill={color} />
      <path d='M12.8008 17.6H14.4008V16H12.8008V17.6Z' fill={color} />
      <path d='M20.7992 17.6H19.1992V16H20.7992V17.6Z' fill={color} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.8 9.6V5.6C4.8 4.8646 4.94485 4.1364 5.22627 3.45697C5.5077 2.77755 5.92019 2.16021 6.4402 1.6402C6.96021 1.12019 7.57755 0.707701 8.25697 0.426275C8.9364 0.144848 9.6646 0 10.4 0C11.1354 0 11.8636 0.144848 12.543 0.426275C13.2225 0.707701 13.8398 1.12019 14.3598 1.6402C14.8798 2.16021 15.2923 2.77755 15.5737 3.45697C15.8552 4.1364 16 4.8646 16 5.6V9.6H18.4C19.0365 9.6 19.647 9.85286 20.0971 10.3029C20.5471 10.753 20.8 11.3635 20.8 12V12.88C21.7042 13.0636 22.5172 13.5542 23.1011 14.2686C23.685 14.983 24.004 15.8773 24.004 16.8C24.004 17.7227 23.685 18.617 23.1011 19.3314C22.5172 20.0458 21.7042 20.5364 20.8 20.72V21.6C20.8 22.2365 20.5471 22.847 20.0971 23.2971C19.647 23.7471 19.0365 24 18.4 24H2.4C1.76348 24 1.15303 23.7471 0.702944 23.2971C0.252856 22.847 0 22.2365 0 21.6L0 12C0 11.3635 0.252856 10.753 0.702944 10.3029C1.15303 9.85286 1.76348 9.6 2.4 9.6H4.8ZM6.4 5.6C6.4 4.53913 6.82143 3.52172 7.57157 2.77157C8.32172 2.02143 9.33913 1.6 10.4 1.6C11.4609 1.6 12.4783 2.02143 13.2284 2.77157C13.9786 3.52172 14.4 4.53913 14.4 5.6V9.6H6.4V5.6ZM13.6 14.4C12.9635 14.4 12.353 14.6529 11.9029 15.1029C11.4529 15.553 11.2 16.1635 11.2 16.8C11.2 17.4365 11.4529 18.047 11.9029 18.4971C12.353 18.9471 12.9635 19.2 13.6 19.2H20C20.6365 19.2 21.247 18.9471 21.6971 18.4971C22.1471 18.047 22.4 17.4365 22.4 16.8C22.4 16.1635 22.1471 15.553 21.6971 15.1029C21.247 14.6529 20.6365 14.4 20 14.4H13.6Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_1104_40402'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
