import React, { useEffect, useRef } from 'react';
import { initializeTurnstile, loadTurnstileScript } from './cloudflare-turnstile-recapcha-logic';

declare global {
  interface Window {
    turnstile: any;
    onTurnstileLoad: () => void;
  }
}

const Turnstile = ({ setReCaptchaToken, widgetRef, setReCAPTCHAError, handleLogin, isNavigateCaptcha }) => {
  useEffect(() => {
    const loadScript = async () => {
      await loadTurnstileScript();

      window.onTurnstileLoad = async () => {
        try {
          // Clean up previous Turnstile instance if any
          if (window.turnstile && widgetRef.current) {
            window.turnstile.remove(widgetRef.current);
          }

          const token = await initializeTurnstile(widgetRef, setReCaptchaToken);
          if (isNavigateCaptcha) handleLogin(token);

          if (!token) {
            setReCAPTCHAError('Recaptcha Error');
          }
        } catch (error) {
          console.error(error);
          setReCAPTCHAError('Recaptcha Error');
        }
      };
    };

    loadScript();

    return () => {
      // Clean up the script and Turnstile instance on component unmount
      const script = document.querySelector(
        'script[src="https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onTurnstileLoad"]',
      );
      if (script) {
        document.body.removeChild(script);
      }
      if (window.turnstile && widgetRef.current) {
        window.turnstile.remove(widgetRef.current);
      }
    };
  }, [setReCaptchaToken, setReCAPTCHAError]);

  return <div className='mt-3 py-0' ref={widgetRef}></div>;
};

export default Turnstile;
