export const PhoneIcon = (props: { color?: string }) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20.4873 17.1402L16.4223 13.4442C16.2301 13.2696 15.9776 13.1764 15.7181 13.1845C15.4585 13.1925 15.2123 13.3011 15.0313 13.4872L12.6383 15.9482C12.0623 15.8382 10.9043 15.4772 9.71228 14.2882C8.52028 13.0952 8.15928 11.9342 8.05228 11.3622L10.5113 8.96824C10.6977 8.78737 10.8064 8.54106 10.8144 8.28145C10.8225 8.02183 10.7292 7.76928 10.5543 7.57724L6.85928 3.51324C6.68432 3.3206 6.44116 3.20374 6.18143 3.1875C5.92171 3.17125 5.66588 3.2569 5.46828 3.42624L3.29828 5.28724C3.12539 5.46075 3.0222 5.69169 3.00828 5.93624C2.99328 6.18624 2.70728 12.1082 7.29928 16.7022C11.3053 20.7072 16.3233 21.0002 17.7053 21.0002C17.9073 21.0002 18.0313 20.9942 18.0643 20.9922C18.3088 20.9786 18.5396 20.8749 18.7123 20.7012L20.5723 18.5302C20.7423 18.3333 20.8286 18.0776 20.8127 17.8179C20.7968 17.5582 20.68 17.315 20.4873 17.1402Z'
        fill={props.color ? props.color : '#35B050'}
      />
    </svg>
  );
};
