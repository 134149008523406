import axios from 'axios';
import { parsePhoneNumber } from 'libphonenumber-js';
import { LanguageType } from '../lib/constants';

const LOKALISE_URL = `https://api.lokalise.com/api2/projects/${
  process.env.LOKALISE_PROJECT_ID ?? '90257586636b6a058ef215.32602055'
}/keys`;
const LOKALISE_TOKEN = process.env.LOKALISE_TOKEN ?? '2034f8427356a828de12411f7beef75f9b3df182';
const headers = {
  'X-Api-Token': LOKALISE_TOKEN,
};
const platforms = ['ios', 'other', 'android', 'web'];
const defaultLang = 'en';
const MAX_KEY_REQUEST_LIMIT = 500;
const FIRST_REQUEST_NUMBER_OF_PAGES = 5;

export const getKeysFromLokalise = async () => {
  // return all keys existing in lokalise
  // https://developers.lokalise.com/reference/list-all-keys-1

  // Make requests for each page of results, up to the limit of `FIRST_REQUEST_NUMBER_OF_PAGES`
  const arrToLoop = Array.from({ length: FIRST_REQUEST_NUMBER_OF_PAGES });
  const lokaliseKeyListResponseArr = await Promise.all(
    arrToLoop.map((_, index) =>
      axios.get(`${LOKALISE_URL}?limit=${MAX_KEY_REQUEST_LIMIT}&page=${index + 1}`, { headers }),
    ),
  );

  // Set up variables for looping through pages of results
  let currentPage = FIRST_REQUEST_NUMBER_OF_PAGES;
  let shouldLoadMorePages = true;

  // If the last request returned the maximum number of keys, load more pages
  const lastRequestKeysLength = lokaliseKeyListResponseArr[lokaliseKeyListResponseArr.length - 1]?.data.keys.length;
  shouldLoadMorePages = lastRequestKeysLength === MAX_KEY_REQUEST_LIMIT;

  while (shouldLoadMorePages) {
    // Make another request for the next page of results
    currentPage++;
    const newLokaliseReponse = await axios.get(`${LOKALISE_URL}?limit=${MAX_KEY_REQUEST_LIMIT}&page=${currentPage}`, {
      headers,
    });
    lokaliseKeyListResponseArr.push(newLokaliseReponse);

    // Check if we should load more pages
    shouldLoadMorePages = newLokaliseReponse.data.keys.length === MAX_KEY_REQUEST_LIMIT;
  }

  // Combine all the arrays of keys into a single array, and extract the key names
  const responseArrays = lokaliseKeyListResponseArr.map((response) => response.data.keys);
  const allKeys = responseArrays.flat();
  return allKeys.map((key) => key.key_name?.web);
};

export const writeKeyToLokalise = async (messageKey: string, messageInLokaliseFormat: string) => {
  const data = JSON.stringify({
    keys: [
      {
        platforms: platforms,
        translations: [{ language_iso: defaultLang, translation: messageInLokaliseFormat }],
        key_name: messageKey,
      },
    ],
  });

  await axios.post(LOKALISE_URL, data, { headers });
};

export const convertPhoneNumber = (phoneNumber: string, isRtl: boolean) => {
  if (!phoneNumber || phoneNumber.length < 5) {
    return null;
  }

  const formattedPhoneNumber = parsePhoneNumber(`+${phoneNumber}`)?.formatInternational();

  return isRtl && formattedPhoneNumber
    ? `${formattedPhoneNumber.split(' ').reverse().join(' ').replace(/\+/, '')}+`
    : formattedPhoneNumber;
};

export const getLanguage = (locale: LanguageType) => {
  return locale === LanguageType.Ar ? 'ar' : 'en';
};
