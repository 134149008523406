declare global {
  interface Window {
    turnstile: any;
    onTurnstileLoad: () => void;
  }
}

export const initializeTurnstile = (widgetRef, setReCaptchaToken) => {
  return new Promise((resolve, reject) => {
    if (widgetRef?.current) {
      window.turnstile.render(widgetRef?.current, {
        sitekey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
        theme: 'light',
        callback: (token) => {
          setReCaptchaToken(token);
          resolve(token);
        },
        'error-callback': () => {
          reject(new Error('Recaptcha Error'));
        },
      });
    } else {
      console.log('not executed');
      reject(new Error('Widget ref not set'));
    }
  });
};

export const loadTurnstileScript = (): Promise<void> => {
  return new Promise<void>((resolve) => {
    const existingScript = document.querySelector(
      'script[src="https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onTurnstileLoad"]',
    );
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onTurnstileLoad';
      script.async = true;
      script.defer = true;
      script.onload = () => resolve();
      document.body.appendChild(script);
    } else {
      resolve();
    }
  });
};
